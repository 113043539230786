<template>
    <div>
        <div class="top-title">
            下载完成，是否安装新版本？
        </div>
        <div class="about-footer">
            <div class="footer-button">
                稍后
            </div>
            <div @click="destoryedAndUpdate()" class="footer-button now-version-button-red">
                关闭并更新
            </div>
        </div>
    </div>
</template>

<script>
if ( typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0 ) {
    var {ipcRenderer, ipcMain} = window.require('electron')
}

import { mapMutations } from "vuex";

export default {
    data() {
        return {
            pac: '',
            showAboutProject: true,
            showData:  null,
            schadule: null,
            isElectron: false,
        }
    },
    created() {
        this.pac = require( "../../../package.json");
        this.isElectron = this.$tools.isElectron();
        if (this.isElectron) {
            // 设置当前的下载进度
            ipcRenderer.on('test', (event, data) => {
            //   console.log('数字', data)
            });
            // 设置当前的下载进度
            ipcRenderer.on('getDownLoadSchedule', (event, data) => {
                // console.log('newval改变了', data);
                this.schadule = parseInt(data.percent * 100);
                // console.log(data);
            });
        }
    },
    methods : {
        ...mapMutations([
        "setVersionUpdatingModal"
        ]),
        destoryedAndUpdate() {
            if (this.isElectron) { 
                ipcRenderer.send("installApp", this.showData);
            }
        }
    },
    watch: {
        "$store.state.newVersionMessage" : {
            handler(newval, oldval) {
                this.showData = newval;
            },
            immediate: true,  //在一开是绑定的时候执行
            deep: true  //深度监听
        }
    }
}
</script>

<style lang="less" scoped>
    
    .top-title {
        margin: 0 auto;
        text-align: center;
        font-size: 18px;
        padding: 30px 0 10px 0; 
    }
    .about-footer {
        // height: 106px;
        text-align: center;
        color: #666;
        font-size: 12px;
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        justify-content: center;
        .footer-button {
            width: 108px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            font-size: 14px;
            color: #999;
            background-color: #EEEEEE;
            border-radius: 20px;
            margin: 55px;
            margin-bottom: 0;
            margin-top: 0;
            transition: all .5s;
            position: relative;
            cursor: pointer;
            &:hover {
                background-color:#FF2400;
                color: #fff;
            }
            &:active{
                left: 0;
                top: 1px;
            }
        }
        .now-version-button-red {
            background-color:#FF2400;
            color: #fff;
        }
    }
</style>